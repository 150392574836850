import React from 'react';
import { ScrollArea } from '@mantine/core';
import styles from '../CompanyDataV2.module.css';
import { assetPrefix, renderNumber } from '../../../../utilities/general';
import useSorting from '../../../../components/CommonFunction/useSorting';

export default function InvestmentListing({ data }) {
  const filterStakingData = data?.filter((val) => val.stakingBalance !== 0);
  const { items } = useSorting(filterStakingData, 'balance_usd');

  return (
    <div className="py-3 h-full">
      <div className={`${styles.HeadingText} px-5`}>Staking Table</div>
      <div className={`${styles.HotColdListingHeader} pt-5 px-5`}>
        <div className={`grid items-start transition-transform cursor-pointer text-right ${styles.InvestmentHeadingGrid} ${styles.HotColdHeading}`}>
          <div className="!text-left">Asset</div>
          <div>
            Staking
            <br />
            Balance
          </div>
          <div>
            Balance
            <br />
            <span className="pr-2"> USD</span>
          </div>
          <div>
            Pending
            <br />
            Rewards
          </div>
          {/* <div>
            Portfolio
            <br />
            Percentage
          </div> */}
        </div>
      </div>
      <ScrollArea style={{ height: '60%' }} type="hover" scrollbarSize={5} scrollHideDelay={200}>
        <div className={`${styles.rowContainerMain}`}>
          {items?.map((val) => (
            <div className={`${styles.rowContainer} pt-2`}>
              <div className={`grid items-start transition-transform px-5 text-right ${styles.InvestmentRowGrid} ${styles.rowText}`}>
                <div className="flex gap-1">
                  <div>
                    <img
                      src={val?.icon ? val?.icon : `${assetPrefix}/images/coinBase.svg`}
                      alt="CoinBase"
                      className="max-w-fit w-4 h-4 rounded-full"
                    />
                  </div>
                  <div>
                    {val?.asset}
                  </div>
                </div>
                {/* <div className="!font-normal">{`${renderNu
                mber(val.stakingBalance)} ${val?.asset}`}</div> */}
                <div className="!font-normal">{`${renderNumber(val.balance)} ${val?.asset}`}</div>
                <div className={`!font-normal ${!val?.balance_usd ? 'pr-4' : ''}`}>{`${renderNumber(val?.balance_usd, true)}`}</div>
                <div className="!font-normal gap-2">
                  <span>{`${renderNumber(val.rewards)} `}</span>
                  <span>{val?.asset ? val.asset : '-'}</span>
                  {/* <span>{val?.pendingBalanceType ? val.pendingBalanceType : '-'}</span> */}
                </div>
                {/* <div className="!font-normal text-[#026DB0]">
                {`${renderNumber(val.portfolioPercentage * 100)}%`}</div> */}
              </div>
            </div>
          ))}
        </div>
      </ScrollArea>
    </div>

  );
}
