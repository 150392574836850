import React, { Suspense, lazy } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Layout from '../../Layout/Layout';
import CompanyDataNavBar from './CompanyDataNavBar';
import commonStyle from '../common.module.css';

const CompanyBookDataDetails = lazy(() => import('../../DetailPages/CompanyData/CompanyBook/CompanyBookDataDetails'));
const BonusPoolDataDetails = lazy(() => import('../../DetailPages/CompanyData/BonusPool/BonusPoolDataDetails'));
const BalanceSheetDetails = lazy(() => import('../../DetailPages/CompanyData/BalanceSheet/BalanceSheetDetails'));
const CompanyDataV2 = () => {
  // minimum grid layout width is 212
  const location = useLocation();
  const navigate = useNavigate();
  const CompanyBookDetailsOverlayShow = !!location.pathname.includes('/company-book-details');
  const BonusPoolDetailsOverlayShow = !!location.pathname.includes('/bonusPool-data-details');
  const BalanceSheetDetailsOverlayShow = !!location.pathname.includes('/balance-sheet-details');

  const handleClose = () => {
    navigate('/company-data-v2');
  };
  return (
    <div
      className={CompanyBookDetailsOverlayShow || BonusPoolDetailsOverlayShow || BalanceSheetDetailsOverlayShow ? `w-full flex h-full ${commonStyle.modalOverlay}` : 'w-full flex'}
    // className="w-full flex h-full"
      aria-hidden="false"
      onClick={handleClose}
    >
      <Layout>

        <CompanyDataNavBar />

        { CompanyBookDetailsOverlayShow && (
        <Suspense>
          <CompanyBookDataDetails />
        </Suspense>
        )}
        {BonusPoolDetailsOverlayShow && (
        <Suspense>
          <BonusPoolDataDetails />
        </Suspense>
        )}
        {BalanceSheetDetailsOverlayShow && (
        <Suspense>
          <BalanceSheetDetails />
        </Suspense>
        )}
      </Layout>
    </div>
  );
};

export default CompanyDataV2;
